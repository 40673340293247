import {useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {createContainer} from 'unstated-next';
import {SaaSRepository} from '../../_proto/services/SaaSRepository';
import {ZipAddressRepository} from '../../_proto/services/ZipAddressRepository';
import {yupUtil} from '../Elements';

const ZIP_ERROR = 'この郵便番号は存在しません';

const SEARCH_ERROR = `入力された住所から位置情報を見つけられません。
ピンの位置の修正をお願いします。`;

export type LngLat = {
  lng: number;
  lat: number;
};

export type AddressPlace = {
  zip?: string;
  pref?: string;
  city?: string;
  detail?: string;
  building?: string;
  location?: LngLat;
  previewLocation?: LngLat;
};

type UsePlacePicker = {
  refine: boolean;
  showRefine(): void;
  onRefineDone(location?: LngLat | null): void;
  searching: boolean;
  searchLonLat(isSetLocation: boolean): Promise<void>;
  searchByZip(): Promise<void>;
  isSetLocation: boolean;
  setIsSetLocation: (value: boolean) => void;
};

/**
 * AddressPlace 型の hook-form の provider 配下でのみ利用可能なので注意
 */
function usePlacePicker(): UsePlacePicker {
  const {getValues, setValue, setError, clearErrors, trigger} =
    useFormContext<AddressPlace>();
  const [refine, setRefine] = useState<boolean>(false);
  const [searching, setSearching] = useState<boolean>(false);
  const [isSetLocation, setIsSetLocation] = useState<boolean>(false);

  const searchLonLat = async (isSetLocation: boolean): Promise<void> => {
    setSearching(true);
    // make search word
    const word = `${getValues('pref')}${getValues('city')}${
      getValues('detail') ?? ''
    }${getValues('building') ?? ''}`;
    // search
    const place = await SaaSRepository.fetchGooglePlace(word).catch(
      () => undefined,
    );
    // set value or error
    if (place && place[0] && place[0].location) {
      setValue('location', place[0].location);
      clearErrors('location.lat');
      if (isSetLocation) {
        setIsSetLocation(true);
        setValue('previewLocation', place[0].location);
      }
    } else {
      setError('location.lat', {type: 'manual', message: SEARCH_ERROR});
    }
    setSearching(false);
  };

  const searchByZip = async (): Promise<void> => {
    setSearching(true);
    // 1. fetch addr
    const zip = yupUtil.zipRequired().cast(getValues('zip')) ?? '';
    const result = await ZipAddressRepository.searchByZipCode(zip).catch(
      () => null,
    );
    // 2. set addr
    if (result) {
      setValue('pref', result.pref);
      setValue('city', result.city);
      if (result.street) {
        setValue('detail', result.street);
      }
      trigger(['pref', 'city', 'detail']).then(() => undefined);
      // 3. fetch location
      await searchLonLat(false);
    } else {
      // error
      setError('zip', {type: 'manual', message: ZIP_ERROR});
    }
    setSearching(false);
  };

  const showRefine = () => {
    setRefine(true);
  };

  const onRefineDone = (location?: LngLat | null) => {
    if (location) {
      setValue('location', location);
      setValue('previewLocation', location);
      clearErrors('location.lat');
    }
    setRefine(false);
  };

  return {
    refine,
    showRefine,
    onRefineDone,
    searching,
    searchByZip,
    searchLonLat,
    isSetLocation,
    setIsSetLocation,
  };
}

export const PlacePickerContainer = createContainer(usePlacePicker);

// 以下、住所だけ（地図なし）版
export type AddressPick = {
  zip: string;
  pref: string;
  city: string;
  detail: string;
  building?: string;
};

type UseAddressPicker = {
  searching: boolean;
  searchByZip(): Promise<void>;
};

/**
 * AddressPick 型の hook-form の provider 配下でのみ利用可能なので注意
 */
function useAddressPicker(): UseAddressPicker {
  const {getValues, setValue, setError, trigger} =
    useFormContext<AddressPick>();
  const [searching, setSearching] = useState<boolean>(false);

  const searchByZip = async (): Promise<void> => {
    setSearching(true);
    // 1. fetch addr
    const zip = yupUtil.zipRequired().cast(getValues('zip')) ?? '';
    const result = await ZipAddressRepository.searchByZipCode(zip).catch(
      () => null,
    );
    // 2. set addr
    if (result) {
      setValue('pref', result.pref);
      setValue('city', result.city);
      if (result.street) {
        setValue('detail', result.street);
      }
      trigger(['pref', 'city', 'detail']).then(() => undefined);
    } else {
      // error
      setError('zip', {type: 'manual', message: ZIP_ERROR});
    }
    setSearching(false);
  };

  return {
    searching,
    searchByZip,
  };
}

export const AddressPickerContainer = createContainer(useAddressPicker);
