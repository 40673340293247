export type HintPattern =
  | 'inviteCode'
  | 'locationPreview'
  | 'couponsCreate'
  | 'couponsHead'
  | 'discount'
  | 'couponPhoto'
  | 'couponBarcode'
  | 'couponDescription'
  | 'couponCondition'
  | 'couponReserve'
  | 'couponUsed'
  | 'couponsMain'
  | 'newsCreate'
  | 'newsPhoto'
  | 'newsMain'
  | 'targetsCreate'
  | 'targetUser'
  | 'targetResidence'
  | 'targetStay'
  | 'targetStation'
  | 'targetAllArea'
  | 'targetsMain'
  | 'creativesCreate'
  | 'creativeTerm'
  | 'creativeBudgetLimit'
  | 'creativesGroupCreate'
  | 'creativesMain'
  | 'reportsMain'
  | 'reportsGroup'
  | 'reportTerm'
  | 'reportEffect'
  | 'reportDeliveryReport'
  | 'reportUserAnalytics'
  | 'reportsCustomer'
  | 'reportRepeater'
  | 'reportResidence'
  | 'reportAttribute'
  | 'reportFollower'
  | 'shopsMain'
  | 'shopsGroups'
  | 'shops'
  | 'shopName'
  | 'shopIcon'
  | 'shopPhoto'
  | 'shopDescription'
  | 'shopLegalUrl'
  | 'shopExcludeVisitors'
  | 'accountMain'
  | 'companiesMain'
  | 'companySetting'
  | 'companyUsers';

export type HintParam = {title: string; comment: string; link: string};

export const Hint: {[P in HintPattern]: HintParam} = {
  inviteCode: {
    title: '招待コード',
    comment:
      '招待コードとはトリマユーザー1人1人に振られているコードです。トリマ広告ダイレクトを紹介してくださったご家族・ご友人の方がトリマユーザーの場合、その方の招待コードを入力していただくことで特典がある場合がございます。',
    link: '',
  },
  locationPreview: {
    title: '地図',
    comment:
      '店舗情報に表示される地図のプレビューです。\n' +
      '地図の中心点の店のアイコンは来店計測の際に基準となる位置を表します。\n' +
      '位置を修正するには、「ピンの位置を修正」ボタンを押して位置情報の修正画面を開き、地図をスクロールして地図の中心点の店のアイコンがお店の位置を正確に指すように設定してください。',
    link: '',
  },
  couponsCreate: {
    title: '広告を作成',
    comment:
      'トリマアプリのおトク画面に掲載する広告の内容を作成します。\n' +
      '広告の内容は値引きや割引などのクーポンに限らず、キャンペーンやイベントのお知らせなど、自由な内容を掲載することができます。\n' +
      '実店舗の場合はトリマのおトク情報画面を店頭で提示していただくことで、クーポンとして特典を提供することができます。',
    link: 'https://support.shop.trip-mile.com/hc/ja/articles/4403983977357',
  },
  couponsHead: {
    title: '見出し1（対象商品・サービス）',
    comment:
      '広告の見出しとしておトク一覧画面、おトク情報詳細画面に表示されます。\n' +
      '見出しの内容は広告の効果に影響するとても重要な要素になります。',
    link: '',
  },
  discount: {
    title: '見出し2（特典内容）',
    comment: `広告の見出しとしておトク一覧画面、おトク情報詳細画面に表示されます。
主に特典やメリットを訴求する内容を表示します。
見出し2には以下の6種類のテンプレートがあります。
・フリーテキスト
・〇〇〇円引き
・〇〇〇円→〇〇〇円
・〇〇〇%OFF
・半額
・無料`,
    link: 'https://support.shop.trip-mile.com/hc/ja/articles/4403983977357',
  },
  couponPhoto: {
    title: '写真',
    comment:
      '広告に掲載する写真を5枚まで設定できます。\n' +
      '画像形式はPNG形式またはJPEG形式です。\n' +
      '画像の縦横比は4：3です。（アップロード時に4：3の比率でトリミングされます。）\n' +
      '画像の大きさは800ピクセル×600ピクセル以上を推奨します。',
    link: '',
  },
  couponBarcode: {
    title: 'バーコード画像',
    comment:
      'POSレジや券売機などでバーコードやQRコードを読み取って値引きを行う場合に利用するバーコード画像、QRコード画像を設定できます。\n' +
      'トリマのおトク情報詳細画面で設定されたバーコード画像が表示されます。\n' +
      '画像形式はPNG形式またはJPEG形式です。\n' +
      '画像の縦横比は4：3です。（アップロード時に4：3の比率でトリミングされます。）',
    link: '',
  },
  couponDescription: {
    title: '詳しい説明',
    comment:
      'おトク情報詳細画面に表示される説明文です。\n' +
      '商品やサービス、特典の魅力が伝わる文章を入力してください。説明文の内容は広告の効果に影響するとても重要な要素になります。\n' +
      'フルパスのURLを入力すると、自動的に外部のWebサイトにリンクすることができます。\n' +
      'リンクのクリック数やクリック率は配信結果から確認することができます。',
    link: '',
  },
  couponCondition: {
    title: '利用条件',
    comment:
      'クーポンの利用条件や注意事項、補足事項などの注記を入力してください。\n' +
      'おトク情報詳細画面の説明文の下に、少し小さいフォントで表示されます。',
    link: '',
  },
  couponReserve: {
    title: 'ランディングページURL',
    comment:
      'ランディングページにリンクするボタンを設置することができます。\n' +
      'リンク先のフルパスのURLと、ボタンのラベルを設定してください。\n' +
      'このボタンのクリック数やクリック率は配信結果から確認することができます。',
    link: '',
  },
  couponUsed: {
    title: 'クーポン利用可能回数',
    comment:
      'クーポンの利用を1度のみに制限することができます。\n' +
      '「1回のみ利用可」に設定した場合、おトク情報詳細画面の下部に『使用済みに変更』ボタンが表示されます。店頭でお客様にクーポンを提示していただく際に『使用済みに変更』ボタンを押し、さらにボタンを右にスワイプしていただくよう案内してください。使用済みに変更した広告は表示されなくなり、1度しか使用できないようになります。\n' +
      '「何回でも利用可」に設定した場合は『使用済みに変更』ボタンは表示されません。',
    link: '',
  },
  couponsMain: {
    title: '作成済みの広告',
    comment:
      'これまでに作成した広告が表示されます。\n' +
      '「︙」ボタンから、広告の編集、複製、配信ができます。\n' +
      '「編集する」：広告の内容を編集できます。すでに配信中の広告の場合でも、配信中の広告の内容を変更することができます。\n' +
      '「複製する」：広告を複製して新規作成することができます。\n' +
      '「配信する」：この広告を配信する画面に遷移します。',
    link: 'https://support.shop.trip-mile.com/hc/ja/articles/4403480517005',
  },
  newsCreate: {
    title: 'お知らせ',
    comment:
      'お知らせとは、トリマユーザーの皆さんにセールなどの情報をお知らせすることができる機能です。',
    link: 'https://support.shop.trip-mile.com/hc/ja/articles/4404207146253',
  },
  newsPhoto: {
    title: '写真',
    comment:
      'お知らせに掲載される写真を5枚まで設定できます。画像の縦横比は4:3で、アップロード時にトリミングできます。また、透過色を利用することはできず、透過部分が含まれる画像は添付時に透過部分が黒色に置き換わりますのでご注意ください。',
    link: '',
  },
  newsMain: {
    title: '作成済みのお知らせ',
    comment: '広告を配信する対象者の設定です。',
    link: 'https://support.shop.trip-mile.com/hc/ja/articles/4403480517005',
  },
  targetsCreate: {
    title: '配信先とは？',
    comment:
      'トリマ広告ダイレクトでは、エリアや属性などの条件で、広告の配信先を絞り込むことができます。絞り込んだ地域や属性の条件をまとめたものを配信先と呼びます。\n' +
      'エリアと属性の条件を組み合わせて配信先を作成することができます。\n' +
      '複数の条件を組み合わせることで、詳細なターゲティングを行うことができます。\n' +
      '作成した配信先は何度も繰り返し利用することができます。',
    link: 'https://support.shop.trip-mile.com/hc/ja/articles/4403410405901',
  },
  targetUser: {
    title: '対象者詳細設定',
    comment:
      '広告を配信する対象者の属性条件を設定します。\n' +
      '「条件を追加する」ボタンから、属性条件の追加をすることができます。\n' +
      '各条件はAND条件（すべての条件を満たす）となり、条件を追加することにより対象者を絞り込みます。',
    link: 'https://support.shop.trip-mile.com/hc/ja/articles/4403410405901',
  },
  targetResidence: {
    title: '居住地・勤務地',
    comment:
      '地図で選択したエリアに居住地、勤務地があるユーザーを配信対象者とします。\n' +
      '地図上のマスをクリックして選択⇔選択解除できます。また、「範囲指定」ボタンから、地図の中心点からの半径1km～10kmの範囲を一括して選択することができます。\n' +
      'ユーザーの滞在履歴の中で、夜間の滞在が一番多いエリアを居住地、日中の滞在が一番多いエリアを勤務地と推定しています。',
    link: 'https://support.shop.trip-mile.com/hc/ja/articles/4403410405901',
  },
  targetStay: {
    title: '滞在履歴',
    comment:
      '地図で選択したエリアに、過去1か月以内で1回以上滞在したことがあるユーザーを配信対象者とします。滞在した曜日と時間帯を指定することができます。\n' +
      '地図上のマスをクリックして選択⇔選択解除できます。また、「範囲指定」ボタンから、地図の中心点からの半径1km～10kmの範囲を一括して選択することができます。',
    link: 'https://support.shop.trip-mile.com/hc/ja/articles/4403410405901',
  },
  targetStation: {
    title: '利用駅',
    comment:
      'ユーザーが過去1か月以内に利用した駅を指定して配信対象者とします。\n' +
      '店舗の最寄駅や沿線の駅など、対象者が利用していると思われる駅を選択してください。\n' +
      '駅名や路線名の名称から検索することができます。',
    link: 'https://support.shop.trip-mile.com/hc/ja/articles/4403410405901',
  },
  targetAllArea: {
    title: '全国へ配信',
    comment:
      'トリマのアンケートに回答してしているトリマユーザー全員を配信対象とします。\n' +
      '居住地・勤務地や滞在履歴、利用駅などと同時に設定することはできません。',
    link: 'https://support.shop.trip-mile.com/hc/ja/articles/4403410405901',
  },
  targetsMain: {
    title: '作成済みの配信先',
    comment:
      'これまでに作成した配信先が表示されます。\n' +
      '「︙」ボタンから、配信先の複製、配信ができます。\n' +
      '「複製する」：配信先を複製して新規作成することができます。\n' +
      '「配信する」：この配信先で広告を配信する画面に遷移します。',
    link: '',
  },
  creativesCreate: {
    title: '配信する',
    comment:
      'この画面から広告を配信します。\n' +
      '配信内容、配信先、配信期間、予算上限を設定し、「配信する」ボタンを押すと広告の配信が行われます。',
    link: 'https://support.shop.trip-mile.com/hc/ja/articles/4403410330509',
  },
  creativeTerm: {
    title: '配信期間',
    comment:
      '開始日時から終了日時までの期間、配信対象者のトリマに広告が掲載されます。\n' +
      '開始日時を未来の日時にすることにより、配信予約となります。\n' +
      '終了日時を過ぎると、すべての配信対象者に広告が表示されなくなります。クーポンの場合はクーポンの利用期限となります。\n' +
      '広告配信後でも「配信管理」メニューから配信期間を変更することができます。',
    link: 'https://support.shop.trip-mile.com/hc/ja/articles/4403410330509',
  },
  creativeBudgetLimit: {
    title: '予算上限',
    comment:
      '予算上限に達した時点で、新しいユーザーへの配信を停止します。既に一度その広告を閲覧したユーザーには、配信期間中は広告が表示されます。\n' +
      '「予算消化予想」を確認して、十分な予算を設定してください。\n' +
      '予算上限は、広告配信後でも変更することができます。予算上限に達してしまった場合は、「配信管理」メニューから予算上限を増額することで配信を再開することができます。\n' +
      '既に広告を閲覧したユーザへの表示は継続したまま新しいユーザへの配信を止めたい場合は、この予算を現在の使用金額より減らしてください。',
    link: 'https://support.shop.trip-mile.com/hc/ja/articles/4403410330509',
  },
  creativesGroupCreate: {
    title: 'グループ一括配信',
    comment: `店舗管理で作成した店舗グループの同一グループに属している店舗に対し、同じ内容の広告を一括で配信できます。

配信先は、各店舗ごとに最低1つは作成する必要があります。（配信先を作成していない店舗は選択できません）
各店舗ごとに予算上限を設定できます。
配信内容は、新規作成するか、店舗グループ内の店舗で作成した広告のうちいずれか1つを選択してください。`,
    link: 'https://support.shop.trip-mile.com/hc/ja/articles/13746046899469',
  },
  creativesMain: {
    title: '配信管理',
    comment:
      '現在配信中/配信開始前の配信と、配信終了した配信の一覧が表示されます。\n' +
      '各配信をクリックすると、配信内容、配信先、配信期間が確認できます。\n' +
      '「配信期間・予算上限の変更」ボタンより、配信期間と予算上限の変更ができます。\n' +
      '現在配信中/配信開始前の場合に表示される「配信終了」ボタンは、配信期間や予算上限にかかわらず、広告の配信を強制終了します。\n' +
      '配信中の広告の内容を変更したい場合は「作成済みの広告」メニューから広告の内容を編集してください。',
    link: 'https://support.shop.trip-mile.com/hc/ja/articles/4403410324621',
  },
  reportsMain: {
    title: '配信結果',
    comment: `配信した広告の結果を見ることができます。前日までの結果を表示します。
店舗全体の効果：
店舗全体における期間内のすべての広告配信結果の合計です。
レポートの集計期間をプルダウンメニューから選択できます。
・広告閲覧人数：１配信あたりで広告閲覧した人数（ユニーク）の合計です。
・広告料：広告を閲覧したユーザー１人あたり3円の広告料の合計です。
・クリック人数：広告内のリンクをクリックした人数（ユニーク）の合計です。
・クリック単価：広告内のリンクをクリックしたユーザー１人あたりにかかった広告料です。（広告料÷リンククリック人数）
・来店延べ人数：広告を閲覧したユーザーのうち店舗に来店したユーザーの延べ人数です。別の日に再度来店した場合は２人とカウントされます。
・来店単価：広告を閲覧して来店したユーザー１人あたりにかかった広告料です。（広告料÷来店延べ人数）
※来店計測は、店舗情報で設定した店舗の位置から半径50m以内に5分以上滞在した場合に計測されます。

集計期間の日毎の数値をCSVデータで出力することができます。

配信ごとの結果：
一覧から配信を選択して、それぞれの配信ごとの結果を見ることができます。`,
    link: 'https://support.shop.trip-mile.com/hc/ja/articles/4403410334861',
  },
  reportsGroup: {
    title: 'グループ配信結果',
    comment: `店舗管理で作成した店舗グループの同一グループに属している各店舗ごとの配信結果を一覧で確認することができます。
レポートの集計期間をプルダウンメニューから選択できます。
また、各店舗ごとの結果の数値をCSVデータで出力することができます。`,
    link: '',
  },
  reportTerm: {
    title: '結果集計期間',
    comment: '広告の配信開始から配信終了までの期間です。',
    link: '',
  },
  reportEffect: {
    title: '効果',
    comment: `配信した広告ごとの効果を見ることができます。
・広告閲覧人数：１配信あたりで広告閲覧した人数（ユニーク）の合計です。
・広告料：広告を閲覧したユーザー１人あたり3円の広告料の合計です。
・クリック人数：広告内のリンクをクリックした人数（ユニーク）の合計です。
・クリック単価：広告内のリンクをクリックしたユーザー１人あたりにかかった広告料です。（広告料÷リンククリック人数）
・来店延べ人数：広告を閲覧したユーザーのうち店舗に来店したユーザーの延べ人数です。別の日に再度来店した場合は２人とカウントされます。
・来店単価：広告を閲覧して来店したユーザー１人あたりにかかった広告料です。（広告料÷来店延べ人数）
※来店計測は、店舗情報で設定した店舗の位置から半径50m以内に5分以上滞在した場合に計測されます。`,
    link: 'https://support.shop.trip-mile.com/hc/ja/articles/4403410334861',
  },
  reportDeliveryReport: {
    title: '配信レポート',
    comment: `配信した広告の詳細なレポートを見ることができます。
広告閲覧人数に対するクリック率、来店率などの割合や、日毎の閲覧回数や来店延べ人数などの数値を表示します。
日毎の数値をCSVデータで出力することができます。`,
    link: 'https://support.shop.trip-mile.com/hc/ja/articles/4403410334861',
  },
  reportUserAnalytics: {
    title: 'ユーザー分析',
    comment: `ユーザー属性セグメントごとの閲覧率・クリック率・来店率や、各セグメントごとのボリュームを見ることができます。
反応の良いセグメントの傾向を知ることにより、より効果的なターゲティングを行うことができます。
右のプルダウンから属性の種類を選択できます。

棒グラフは、どのセグメントのボリュームが多いかを表します。
・一覧表示：一覧表示したユーザーの各セグメントの割合を表します。
・広告閲覧：広告閲覧したユーザーの各セグメントの割合を表します。
・リンククリック：リンクをクリックしたしたユーザーの各セグメントの割合を表します。
・来店：来店が計測されたユーザーの各セグメントの割合を表します。

線グラフは、どのセグメントの反応が良いかを表します。
・閲覧率：一覧表示したユーザーのうち、広告を閲覧したユーザーの割合をセグメント別に表します。
・クリック率：広告を閲覧したユーザーのうち、リンクをクリックしたユーザーの割合をセグメント別に表します。
・来店率：広告を閲覧したユーザーのうち、来店が計測されたユーザーの割合をセグメント別に表します。`,
    link: '',
  },
  reportsCustomer: {
    title: '来店者分析',
    comment:
      '来店者の傾向を分析することができます。\n' +
      '広告を見て店舗に一度でも来店したことがある人を来店者としています。\n' +
      '※来店計測は、店舗情報で設定した店舗の位置から半径50m以内に5分以上滞在した場合に計測されます。',
    link: 'https://support.shop.trip-mile.com/hc/ja/articles/4404927067021',
  },
  reportRepeater: {
    title: '来店者リピート率',
    comment:
      '広告を見て店舗に一度でも来店したことがある人を来店者としています。\n' +
      'これまでの全来店者のうち、２回以上来店した人の割合を表します。\n' +
      '来店者数：広告を見て店舗に一度でも来店したことがある人の人数（ユニーク）です。\n' +
      'リピート来店者数：来店者のうち、２回以上来店した人の人数（ユニーク）です。\n' +
      'リピート率：来店者のうち、再来店した人（２回以上来店した人）の割合です。',
    link: 'https://support.shop.trip-mile.com/hc/ja/articles/4404927067021',
  },
  reportResidence: {
    title: '来店者居住地/勤務地',
    comment:
      '来店者の居住地・勤務地の分布を地図で確認することができます。\n' +
      '実際の店舗の商圏を調べることができます。\n' +
      'オレンジ色の 濃いマスほど来店者が多いエリアです。',
    link: 'https://support.shop.trip-mile.com/hc/ja/articles/4404927067021',
  },
  reportAttribute: {
    title: '来店者属性',
    comment: '来店者の年齢・性別の割合をグラフで確認することができます。',
    link: 'https://support.shop.trip-mile.com/hc/ja/articles/4404927067021',
  },
  reportFollower: {
    title: 'フォロワー数推移',
    comment: '店舗をフォローしているユーザー数の推移を確認することができます。',
    link: 'https://support.shop.trip-mile.com/hc/ja/articles/4404927067021',
  },
  shopsMain: {
    title: '店舗管理',
    comment:
      '店舗や店舗グループの作成、編集を行います。\n' +
      '店舗は、企業アカウントの中に複数作成できます。\n' +
      '広告の配信は各店舗ごとに行います。',
    link: '',
  },
  shopsGroups: {
    title: '店舗グループ',
    comment:
      'チェーン店など複数の店舗をグループにまとめることができます。\n' +
      '同一のグループに所属している店舗は、「グループ一括配信」から、同じ内容の広告を一括で配信できます。',
    link: '',
  },
  shops: {
    title: '店舗',
    comment:
      '店舗は、企業アカウントの中に複数作成できます。\n' +
      '広告の配信は各店舗ごとに行い、店舗ごとに配信結果を確認できます。\n' +
      '広告料の請求は企業アカウントごとになります。',
    link: '',
  },
  shopName: {
    title: '店舗名',
    comment: '広告や店舗情報に表示されるお店の名前になります。',
    link: '',
  },
  shopIcon: {
    title: '店舗アイコン',
    comment:
      '広告や店舗情報に店舗名と共に表示される円形のアイコンになります。\n' +
      'お店のロゴやシンボルマークなどを表示することができます。\n' +
      '自動的に円形にトリミングされますので、縦横比1：1の正方形の画像を設定してください。',
    link: '',
  },
  shopPhoto: {
    title: '店舗紹介写真',
    comment:
      '店舗情報に掲載される写真を5枚まで設定できます。\n' +
      '画像形式はPNG形式またはJPEG形式です。\n' +
      '画像の縦横比は4：3です。（アップロード時に4：3の比率でトリミングされます。）\n' +
      '画像の大きさは800ピクセル×600ピクセル以上を推奨します。',
    link: '',
  },
  shopDescription: {
    title: '紹介文',
    comment:
      '広告や店舗情報に表示されるお店の紹介文になります。\n' +
      'お店の魅力が伝わるような文章を入力してください。',
    link: '',
  },
  shopLegalUrl: {
    title: '特定商取引法に基づく表記のURL',
    comment:
      'ECサイトなどの無店舗型のサービスの場合、Webサイト内に必ず特定商取引法に基づく表記を行うことが法律で義務付けられています。\n' +
      '特定商取引法に基づく表記があるページのURLを設定してください。\n' +
      '特定商取引法の対象でないWebサイトの場合は、広告主の情報や問い合わせ先が明記されたページのURLを設定してください。',
    link: '',
  },
  shopExcludeVisitors: {
    title: '来店集計除外トリマアカウント',
    comment:
      'トリマ広告ダイレクトでは、来店したトリマユーザーを来店者として集計しています。ただ、店内にいるトリマユーザーを集計しているため、オーナー様や従業員の方がトリマユーザーの場合、来店者として集計されてしまいます。\n' +
      'そのため、オーナー様等の招待コード（トリマのマイページから確認可）を、来店集計除外トリマアカウントとして登録していただくと、そのアカウントは来店者として計測されなくなります。',
    link: '',
  },
  accountMain: {
    title: 'アカウント設定',
    comment:
      'お客様が登録したユーザーアカウントの設定ができます。\n' +
      '携帯電話番号（SMSの送信先）、メールアドレス、パスワード、名前が変更できます。\n' +
      'メール送信設定では、広告配信結果レポートメール、予算上限到達通知メールの送信可否を設定できます。',
    link: '',
  },
  companiesMain: {
    title: '企業',
    comment:
      '「企業」とは、トリマ広告ダイレクトの広告料のご請求先となるアカウントとなります。\n' +
      '１ユーザーにつき複数の企業アカウントを作成したり、複数の企業アカウントに所属することができます。\n' +
      '複数のクライアントを持つ代理店様や、同じ会社の別の部署で利用する場合、料金の請求を分けたい場合は、それぞれで企業アカウントを作成してください。',
    link: 'https://support.shop.trip-mile.com/hc/ja/articles/6309451769741',
  },
  companySetting: {
    title: '企業設定',
    comment:
      'この画面では、企業に紐づく企業情報とお支払い方法と所属ユーザーの情報を閲覧することができます。\n\nオーナーユーザーであれば、それぞれの情報の変更が可能です。',
    link: '',
  },
  companyUsers: {
    title: '所属ユーザーの管理',
    comment: `この画面では、所属ユーザーの新規招待と変更が可能です。

新規招待を行うと、企業に所属する一般ユーザーを増やすことができます。案内に従って、招待メールを送信してください。

所属ユーザーの変更は、所属ユーザーごとに変更ボタンがあります。
ユーザーの所属の解除と権限の変更が可能です。
現在招待中のユーザーは変更ができません。所属ユーザーとして登録済みの方のみ変更が可能です。`,
    link: '',
  },
};
