import {LoadingButton} from '@mui/lab';
import {Box} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {StyleSheet, View, ViewStyle} from 'react-native';
import {SaaSShop} from '../../API';
import {ShopsContainer, StepNavigationContainer} from '../../container';
import {AccountMenu} from '../../navigation/AccountMenu';
import {axiosHelper} from '../../service';
import {CommonStyles} from '../../theme';
import {
  Container,
  FormBlock,
  Header,
  HelperText,
  MaxWidth,
  Menu,
  RequiredNotice,
  Submenu,
  Text,
  TrimaLoadingButton,
  VForm,
  VMargin,
  WithHint,
} from '../Elements';
import {NotRequired} from '../Elements/Text';
import {ImagePicker} from '../ImagePicker';
import {PlacePicker, PlacePickerContainer} from '../PlacePicker';
import {BRAKE_POINT, Media, useResponsive} from '../Responsive';
import {ShopPreview} from '../Shop/ShopPreview';
import {ShopRegisterContainer} from './container';
import {
  ICON_LIMIT,
  ICON_SIZE,
  ShopFormData,
  categoryProps,
  schemaShopForm,
  shopFormInfos,
  shopFormRadio,
} from './shopSchema';

const ImageForms: React.FC = () => {
  const {
    icon,
    images,
    addIcon,
    removeIcon,
    changeIcon,
    addImage,
    removeImage,
    changeImage,
  } = ShopRegisterContainer.useContainer();
  const {
    formState: {errors},
    getValues,
    setValue,
    trigger,
  } = useFormContext<ShopFormData>();
  useEffect(() => {
    const imageEnabled = images.length > 0;
    const old = getValues('imageEnabled');
    setValue('imageEnabled', imageEnabled);
    if (old || imageEnabled) {
      trigger('imageEnabled');
    }
  }, [getValues, images, setValue, trigger]);
  return (
    <View>
      <View style={CommonStyles.margin.bottom}>
        <View style={[CommonStyles.flex.row, CommonStyles.flex.crossCenter]}>
          <WithHint id="shopIcon">
            <Text>店舗アイコン</Text>
            <NotRequired />
          </WithHint>
        </View>
        <ImagePicker
          image={icon}
          onPicked={addIcon}
          onRemove={removeIcon}
          aspect={1}
          maxPx={ICON_LIMIT}
          size={ICON_SIZE}
          onChange={changeIcon}
          showDialog={false}
        />
      </View>
      <View style={CommonStyles.margin.bottom}>
        <View style={[CommonStyles.flex.row, CommonStyles.flex.crossCenter]}>
          <WithHint id={'shopPhoto'}>
            <Text>店舗紹介写真</Text>
          </WithHint>
          <RequiredNotice />
        </View>
        <View style={CommonStyles.flex.row}>
          {[...Array(5)].map((_, i) => {
            return (
              <ImagePicker
                index={i}
                key={'picker_' + i}
                image={images[i]}
                style={styles.shopPicker}
                disabled={i !== images.length}
                onPicked={addImage}
                onRemove={() => removeImage(i)}
                onChange={changeImage}
              />
            );
          })}
        </View>
        <HelperText type="error">{errors.imageEnabled?.message}</HelperText>
      </View>
    </View>
  );
};

// Todo 他でも使うだろうから共通コンポーネント化したほうが多分いい
const AddressForm: React.FC = () => {
  const {searchByZip, searching} = PlacePickerContainer.useContainer();
  return (
    <View>
      <Submenu>所在地</Submenu>
      <View style={CommonStyles.flex.row}>
        <View style={CommonStyles.flex.full}>
          <VForm.Text<ShopFormData> {...shopFormInfos.zip} />
        </View>
        <LoadingButton
          sx={{
            height: 40,
            width: 100,
            marginTop: '28px',
            marginLeft: 2,
            borderRadius: 2,
          }}
          variant="contained"
          onClick={searchByZip}
          disabled={searching}
          loading={searching}>
          {searching ? '' : '自動入力'}
        </LoadingButton>
      </View>
      <VForm.Text<ShopFormData> {...shopFormInfos.pref} editable={false} />
      <VForm.Text<ShopFormData> {...shopFormInfos.city} editable={false} />
      <VForm.Text<ShopFormData> {...shopFormInfos.detail} />
      <VForm.Text<ShopFormData> {...shopFormInfos.building} />
    </View>
  );
};

const Forms: React.FC = () => {
  const {watch} = useFormContext<ShopFormData>();
  const isReal: unknown = watch('isReal');
  let isRealBool = true;
  if (isReal !== undefined) {
    isRealBool = JSON.parse(isReal as string);
  }
  const realUrls = {required: true, ...shopFormInfos.url};
  delete realUrls.notRequired;
  return (
    <View>
      <MaxWidth maxWidth={586}>
        <VForm.Text<ShopFormData> {...shopFormInfos.name} />
        <ImageForms />
        <VForm.Text<ShopFormData> {...shopFormInfos.text} />
        {isRealBool && (
          <>
            <AddressForm />
            <PlacePicker />
          </>
        )}
        <VMargin />
        <VForm.Text<ShopFormData> {...shopFormInfos.phone} />
        <VForm.Text<ShopFormData> {...shopFormInfos.bizHours} />
        <VForm.Text<ShopFormData> {...shopFormInfos.holidays} />
        {isRealBool ? (
          <VForm.Text<ShopFormData> {...shopFormInfos.url} />
        ) : (
          <>
            <VForm.Text<ShopFormData> {...shopFormInfos.legalUrl} />
            <VForm.Text<ShopFormData> {...realUrls} />
          </>
        )}
        <VForm.Select<ShopFormData> {...categoryProps} />
        <View style={CommonStyles.margin.bottom} />
      </MaxWidth>
    </View>
  );
};

const Submit: React.FC = () => {
  const [errorMsg, setErrorMsg] = useState('');
  const {createShop, isLoading} = ShopRegisterContainer.useContainer();
  const {isSetLocation} = PlacePickerContainer.useContainer();
  const {setStep2Main} = StepNavigationContainer.useContainer();
  const {setShopList} = ShopsContainer.useContainer();
  const {
    watch,
    handleSubmit,
    formState: {isValid},
  } = useFormContext<ShopFormData>();
  const onSubmit = async (data: ShopFormData) => {
    try {
      const shop = await createShop(data);
      console.log('[ShopRegister] submitted', shop);
      setShopList([shop]);
      setStep2Main();
    } catch (error: any) {
      setErrorMsg(axiosHelper.commonErrorHandler(error));
    }
  };
  const isReal: unknown = watch('isReal');
  let isRealBool = true;
  if (isReal !== undefined) {
    isRealBool = JSON.parse(isReal as string);
  }
  const helper = !isValid
    ? '必要項目の入力をお願いします'
    : !isSetLocation && isRealBool
    ? '「所在地を地図に反映する」を押してください'
    : errorMsg;

  return (
    <View style={styles.submit}>
      <TrimaLoadingButton
        variant="contained"
        onClick={handleSubmit(onSubmit, (errors) => console.log(errors))}
        disabled={!isValid || isLoading || (!isSetLocation && isRealBool)}
        loading={isLoading}
        loadingPosition="start">
        店舗を登録
      </TrimaLoadingButton>
      <HelperText type="error">{helper}</HelperText>
    </View>
  );
};

// 店舗追加・更新用
export const SubmitForAdd: React.FC<{
  mode?: 'new' | 'edit';
  onSubmit?(shop: SaaSShop): void;
  groupId?: string | null;
}> = ({mode, onSubmit, groupId}) => {
  const [errorMsg, setErrorMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const {createShop} = ShopRegisterContainer.useContainer();
  const {isSetLocation, setIsSetLocation} = PlacePickerContainer.useContainer();
  const {
    watch,
    handleSubmit,
    setValue,
    formState: {isValid, errors},
  } = useFormContext<ShopFormData>();
  const submitHandler = async (data: ShopFormData) => {
    try {
      setIsLoading(true);
      // 作成 or 更新
      const shop = await createShop(groupId ? {...data, groupId} : data);
      if (shop.id) {
        setValue('id', shop.id);
      }
      onSubmit && (await onSubmit(shop));
      setIsLoading(false);
    } catch (error: any) {
      setErrorMsg(axiosHelper.commonErrorHandler(error));
    }
  };
  const isFormError = errors && Object.keys(errors).length > 0;
  const isReal: unknown = watch('isReal');
  let isRealBool = true;
  if (isReal !== undefined) {
    isRealBool = JSON.parse(isReal as string);
  }
  const helper =
    !isValid || isFormError
      ? '必要項目の入力をお願いします'
      : !isSetLocation && isRealBool
      ? '「所在地を地図に反映する」を押してください'
      : errorMsg;
  React.useEffect(() => {
    if (mode === 'edit') {
      setIsSetLocation(true);
    }
  }, [mode]);

  return (
    <View style={styles.submit}>
      <TrimaLoadingButton
        variant="contained"
        onClick={handleSubmit(submitHandler, (errors) => console.log(errors))}
        disabled={!isValid || isLoading || (!isSetLocation && isRealBool)}
        loading={isLoading}
        loadingPosition="start">
        {mode === 'new' ? '店舗を追加' : '店舗情報を更新'}
      </TrimaLoadingButton>
      <HelperText type="error">{helper}</HelperText>
    </View>
  );
};

const Wrap: React.FC<{style?: ViewStyle; children: React.ReactNode}> = ({
  children,
  style,
}) => {
  return <View style={[styles.container, style]}>{children}</View>;
};

// for 加盟店登録
export const ShopRegisterComponent: React.FC = () => {
  const {isOverDesktop} = useResponsive();
  return (
    <View>
      <Header rightItem={<AccountMenu />} />
      <Container>
        <MaxWidth maxWidth={1008} style={CommonStyles.margin.all}>
          <Menu>店舗情報入力</Menu>
          <VMargin />
          <Text>店舗情報は、店舗管理画面からいつでも修正できます。</Text>
          <VMargin />

          <VForm.Provider<ShopFormData> schema={schemaShopForm}>
            <ShopRegisterContainer.Provider>
              <PlacePickerContainer.Provider>
                <Media mobile>
                  <RealShopForm />
                  <Wrap>
                    <View style={CommonStyles.margin.top} />
                    <Forms />
                  </Wrap>
                  <Wrap>
                    <ShopPreview />
                  </Wrap>
                  <View style={CommonStyles.margin.bottom} />
                </Media>
                <Media tablet desktop>
                  <View style={styles.desktop}>
                    <View style={isOverDesktop ? styles.max : styles.fullWidth}>
                      <View style={[CommonStyles.flex.row]}>
                        <Wrap style={styles.leftPanel}>
                          <ShopPreview />
                        </Wrap>
                        <View style={styles.rightPanel}>
                          <RealShopForm />
                          <Wrap>
                            <FormBlock>
                              <Forms />
                            </FormBlock>
                          </Wrap>
                        </View>
                      </View>
                    </View>
                  </View>
                </Media>
                <MaxWidth maxWidth={586}>
                  <Submit />
                  <VMargin />
                  <Text>
                    店舗情報は、店舗管理画面からいつでも修正できます。
                  </Text>
                </MaxWidth>
              </PlacePickerContainer.Provider>
            </ShopRegisterContainer.Provider>
          </VForm.Provider>
        </MaxWidth>
      </Container>
    </View>
  );
};

export const RealShopForm: React.FC = () => {
  const {watch} = useFormContext<ShopFormData>();
  const isReal: unknown = watch('isReal');
  let isRealBool = true;
  if (isReal !== undefined) {
    isRealBool = JSON.parse(isReal as string);
  }

  return (
    <Box ml={2}>
      <VForm.Radio<ShopFormData> {...shopFormRadio.isReal} />
      <Text>
        {isRealBool
          ? '店舗の所在地の設定により来店数を計測します。'
          : '店舗の所在地の設定および来店数の計測は行いません。'}
      </Text>
    </Box>
  );
};

// for 店舗作成/編集
export const ShopCreateComponent: React.FC = () => {
  return (
    <View>
      <Forms />
    </View>
  );
};

const styles = StyleSheet.create({
  autoInput: {
    height: 40,
    width: 100,
    marginTop: 28,
    ...CommonStyles.margin.left,
  },
  autoInputContent: {
    height: 40,
  },
  submit: {marginTop: 32},
  shopPicker: {margin: 2},
  container: {
    ...CommonStyles.formBlock,
    ...CommonStyles.margin.all,
    marginBottom: 0,
  },
  desktop: {
    alignItems: 'center',
  },
  fullWidth: {
    alignSelf: 'stretch',
  },
  max: {
    width: BRAKE_POINT.desktop,
  },
  leftPanel: {
    marginRight: 0,
  },
  rightPanel: {
    flex: 1,
  },
});
