import {SaaSImageContents} from '../../_proto/services/SaaSRepository';
import {SaaSShopDto} from '../../_proto/services/SaaSShopRepository';
import {LngLatIPC} from '../../_proto/services/ZipAddressRepository';
import {GetSaaSShopQuery, ShopCategory} from '../../API';
import {shopCategoryLabels} from '../../container';
import {getImages} from '../../service';
import {
  PLACEHOLDER,
  RadioInfos,
  SelectProps,
  STRING_LIMIT,
  TextInputProps,
  yup,
  yupUtil,
} from '../Elements';
import {AddressPlace, lngLat2lonLat} from '../PlacePicker';

const DEFAULT_CENTER = {
  lat: 35.658517,
  lng: 139.70133399999997,
};

export type ShopFormData = {
  id?: string;
  name: string;
  text: string;
  phone?: string;
  bizHours: string;
  holidays: string;
  url?: string;
  legalUrl?: string;
  category: ShopCategory;
  // 店舗画像、店舗紹介写真はform外で管理する。画像の有無だけ管理
  imageEnabled: boolean;
  isReal: boolean;
} & AddressPlace;

export function convert(
  formData: ShopFormData & {groupId?: string},
  ownerId: string,
): Omit<SaaSShopDto, 'images' | 'icon'> {
  const {
    zip,
    pref,
    city,
    detail,
    building,
    bizHours,
    url,
    legalUrl,
    location,
    isReal,
    ...rest
  } = formData;
  if (isReal) {
    return {
      address: {
        zip: zip ?? '',
        pref: pref ?? '',
        city: city ?? '',
        detail: detail ?? '',
        building: building ?? '',
      },
      biztimes: bizHours,
      url: url ?? '',
      legalUrl: '',
      location: location
        ? lngLat2lonLat(location)
        : lngLat2lonLat(DEFAULT_CENTER),
      ownerId,
      ...rest,
    };
  } else {
    return {
      address: {
        zip: '',
        pref: '',
        city: '',
        detail: '',
        building: '',
      },
      biztimes: bizHours,
      url: url ?? '',
      legalUrl: legalUrl ?? '',
      ownerId,
      ...rest,
    };
  }
}
export type ShopImages = {
  images: SaaSImageContents[];
  icon?: SaaSImageContents;
};
export type ShopRestored = {
  forms: ShopFormData;
} & ShopImages & {
    groupId?: string | null;
  };

export type EditMode = 'edit' | 'copy';

export async function convertForRestore(
  shop: NonNullable<GetSaaSShopQuery['getSaaSShop']>,
  editMode: EditMode,
): Promise<ShopRestored> {
  if (!shop.category || !shop.imageUrls) {
    throw '店舗データの取得に失敗しました';
  }
  const forms = {
    id: editMode === 'edit' ? shop.id : '',
    zip: shop.address?.zip ?? '',
    pref: shop.address?.pref ?? '',
    city: shop.address?.city ?? '',
    detail: shop.address?.detail ?? '',
    building: shop.address?.building ?? '',
    holidays: shop.holidays ?? '',
    bizHours: shop.biztimes ?? '',
    category: shop.category,
    location: {lng: shop.location?.lon ?? 0, lat: shop.location?.lat ?? 0},
    name: (shop.name ?? '') + (editMode === 'copy' ? ' のコピー' : ''),
    phone: shop.phone ?? '',
    text: shop.text ?? '',
    url: shop.url ?? '',
    legalUrl: shop.legalUrl ?? '',
    imageEnabled: !!(shop.imageUrls?.length && shop.imageUrls?.length > 0),
    isReal: !shop.legalUrl,
  };
  const images: SaaSImageContents[] = await getImages(shop.imageUrls);
  const icon = shop.icon ? {uri: shop.icon, key: 'icon'} : undefined;
  return {forms, images, icon, groupId: shop.groupId};
}

export const schemaShopForm: yup.SchemaOf<ShopFormData> = yup.object().shape({
  id: yupUtil.string(),
  name: yupUtil.stringRequired(STRING_LIMIT.shopName),
  text: yupUtil.stringRequired(STRING_LIMIT.shopText),
  zip: yup.mixed().when('isReal', {
    is: true,
    then: yupUtil.zipRequired(),
    otherwise: yup.string().strip(),
  }),
  pref: yup.mixed().when('isReal', {
    is: true,
    then: yupUtil.stringRequired(),
    otherwise: yup.string().strip(),
  }),
  city: yup.mixed().when('isReal', {
    is: true,
    then: yupUtil.stringRequired(),
    otherwise: yup.string().strip(),
  }),
  detail: yup.mixed().when('isReal', {
    is: true,
    then: yupUtil.stringRequired(STRING_LIMIT.detail),
    otherwise: yup.string().strip(),
  }),
  building: yup.mixed().when('isReal', {
    is: true,
    then: yupUtil.string(STRING_LIMIT.building),
    otherwise: yup.string().strip(),
  }),
  location: yup.mixed().when('isReal', {
    is: true,
    then: yup
      .object({
        lng: yupUtil.numberRequired().default(LngLatIPC.lng),
        lat: yupUtil.numberRequired().default(LngLatIPC.lat),
      })
      .required(),
    otherwise: yup.object().strip(),
  }),
  previewLocation: yup.mixed().when('isReal', {
    is: true,
    then: yup
      .object({
        lng: yupUtil.numberRequired().default(LngLatIPC.lng),
        lat: yupUtil.numberRequired().default(LngLatIPC.lat),
      })
      .required(),
    otherwise: yup.object().strip(),
  }),
  phone: yupUtil.phoneOptional(),
  bizHours: yupUtil.stringRequired(STRING_LIMIT.bizHours),
  holidays: yupUtil.stringRequired(STRING_LIMIT.holidays),
  url: yup.mixed().when('isReal', {
    is: false,
    then: yupUtil.stringRequired(STRING_LIMIT.url),
  }),
  legalUrl: yup.mixed().when('isReal', {
    is: false,
    then: yupUtil.stringRequired(STRING_LIMIT.url),
    otherwise: yup.string().strip(),
  }),
  category: yup
    .mixed<ShopCategory>()
    .oneOf(Object.values(ShopCategory))
    .required(),
  imageEnabled: yupUtil.imageRequired(),
  isReal: yupUtil.booleanRequired(),
});

export const shopFormInfos: {
  [P in keyof Required<
    Omit<
      ShopFormData,
      | 'id'
      | 'category'
      | 'imageEnabled'
      | 'location'
      | 'previewLocation'
      | 'isReal'
    >
  >]: TextInputProps<ShopFormData>;
} = {
  name: {
    name: 'name',
    label: '店舗名',
    maxLength: STRING_LIMIT.shopName,
    ...PLACEHOLDER.shopName,
    required: true,
    withHint: 'shopName',
  },
  text: {
    name: 'text',
    label: '紹介文',
    maxLength: STRING_LIMIT.shopText,
    multiline: true,
    style: {height: 320},
    ...PLACEHOLDER.shopText,
    required: true,
    withHint: 'shopDescription',
  },
  zip: {
    name: 'zip',
    label: '郵便番号',
    ...PLACEHOLDER.zip,
    required: true,
  },
  pref: {
    name: 'pref',
    label: '都道府県',
    ...PLACEHOLDER.pref,
    required: true,
  },
  city: {
    name: 'city',
    label: '市区町村',
    ...PLACEHOLDER.city,
    required: true,
  },
  detail: {
    name: 'detail',
    label: '町・番地',
    maxLength: STRING_LIMIT.detail,
    ...PLACEHOLDER.detail,
    required: true,
  },
  building: {
    name: 'building',
    label: '建物名称',
    maxLength: STRING_LIMIT.building,
    ...PLACEHOLDER.building,
    notRequired: true,
  },
  phone: {
    name: 'phone',
    label: '電話番号',
    ...PLACEHOLDER.phone,
    notRequired: true,
  },
  bizHours: {
    name: 'bizHours',
    label: '営業時間',
    multiline: true,
    maxLength: STRING_LIMIT.bizHours,
    ...PLACEHOLDER.shopBizHours,
    required: true,
  },
  holidays: {
    name: 'holidays',
    label: '休業日',
    multiline: true,
    maxLength: STRING_LIMIT.holidays,
    ...PLACEHOLDER.shopHolidays,
    required: true,
  },
  url: {
    name: 'url',
    label: 'Webサイト',
    notRequired: true,
    maxLength: STRING_LIMIT.url,
    ...PLACEHOLDER.url,
  },
  legalUrl: {
    name: 'legalUrl',
    label: '特定商取引法に基づく表記のURL',
    maxLength: STRING_LIMIT.url,
    ...PLACEHOLDER.url,
    required: true,
    withHint: 'shopLegalUrl',
  },
};

export const shopFormRadio: RadioInfos<ShopFormData, 'isReal'> = {
  isReal: {
    name: 'isReal',
    items: {true: '実店舗あり', false: '実店舗なし'},
    defaultValue: true,
  },
};

const shopCategoryOptions: {
  label: string;
  value: ShopCategory;
}[] = Object.values(ShopCategory)
  .map((value) => {
    return {label: shopCategoryLabels[value], value};
  })
  .filter((v) => v.label);

export const categoryProps: SelectProps<ShopFormData> = {
  name: 'category',
  label: 'カテゴリ',
  options: shopCategoryOptions,
  defaultValue: ShopCategory.GOURMET,
  required: true,
  menuPortalTarget: document.querySelector('form'),
  menuPlacement: 'top',
};

export const ICON_LIMIT = 160;
export const ICON_SIZE = {width: 160, height: 160};
