import {Box} from '@mui/material';
import {useNavigation, useRoute} from '@react-navigation/native';
import React from 'react';
import {FlatList, Image, StyleSheet, View, ViewStyle} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';
import {GetSaaSShopQuery, SaaSShop} from '../../API';
import CenterMarkerMap from '../../_proto/parts/map/CenterMarkerMap';
import {MapContainer} from '../../_proto/parts/map/MapContainer';
import {SaaSImageContents} from '../../_proto/services/SaaSRepository';
import {BRAKE_POINT, Media, useResponsive} from '../../components';
import {
  BackLink,
  Container,
  FormBlock,
  MaxWidth,
  Menu,
  Paragraph,
  Text,
  TrimaButton,
  VMargin,
  WithHint,
} from '../../components/Elements';
import {ShopDeleteDialog} from '../../components/Shop/ShopDeleteDialog';
import {ShopPreviewDisplay} from '../../components/Shop/ShopPreview';
import {ShopContainer, shopCategoryLabels} from '../../container';
import {MainNavigationProp, MainRouteProp} from '../../navigation/MainScreen';
import {getImages, graphQLService} from '../../service';
import {Colors, CommonStyles, Message} from '../../theme';
import {ExcludeVisitorsContainer} from './ExcludeVisitors/ExcludeVisitorsContainer';

type NProp = MainNavigationProp<'ShopsDetail'>;
type RProp = MainRouteProp<'ShopsDetail'>;

// 店舗アイコン表示パーツ
const IconImage: React.FC<{uri?: string | null}> = ({uri}) => {
  return (
    <View>
      <Text>店舗アイコン</Text>
      {uri && <Image source={{uri}} style={styles.iconImage} />}
    </View>
  );
};

// 店舗イメージ表示パーツ
const ShopImages: React.FC<{imageUrls?: (string | null)[] | null}> = ({
  imageUrls,
}) => {
  const [images, setImages] = React.useState<SaaSImageContents[] | undefined>();
  const [error, setError] = React.useState<Error | undefined>();
  React.useEffect(() => {
    if (!imageUrls) {
      return setImages([]);
    }
    getImages(imageUrls).then(setImages).catch(setError);
  }, [imageUrls]);
  const renderContent = () => {
    if (error) {
      return (
        <View>
          <VMargin />
          <Text>{error.message}</Text>
        </View>
      );
    }
    if (!images) {
      return <ActivityIndicator size={20} />;
    }
    if (images.length === 0) {
      return (
        <View>
          <VMargin />
          <Text>画像一覧の取得に失敗しました</Text>
        </View>
      );
    }
    return (
      <View style={CommonStyles.flex.row}>
        {images.map((image) => {
          return <Image source={image} style={styles.shopImage} />;
        })}
      </View>
    );
  };
  return (
    <View>
      <Text>店舗紹介写真</Text>
      {renderContent()}
    </View>
  );
};

// 店舗位置表示パーツ
const Place: React.FC<{
  location?: {lat?: number | null; lon?: number | null} | null;
}> = ({location}) => {
  const lnglat =
    location?.lat && location.lon
      ? {lat: location.lat, lng: location.lon}
      : undefined;
  return (
    <View>
      <Text>地図</Text>
      <VMargin />
      {lnglat ? (
        <View style={styles.map}>
          <MapContainer.Provider>
            <CenterMarkerMap center={lnglat} zoom={17} freezed />
          </MapContainer.Provider>
        </View>
      ) : (
        <Text>位置情報の取得に失敗しました</Text>
      )}
    </View>
  );
};

const VisitorsComponent = ({id}: {id: string | undefined}) => {
  const navigation = useNavigation<NProp>();
  const {visitors, getData} = ExcludeVisitorsContainer.useContainer();
  const [error, setError] = React.useState<Error | undefined>();
  const gotoExcludeVisitors = () =>
    navigation.navigate('ExcludeVisitorsMain', {id});
  React.useEffect(() => {
    async function fetchData() {
      console.log('id', id);
      if (id) {
        try {
          // const result = await graphQLService.getShopExcludeVisitorList(id);
          // setVisitors(result);
          await getData(id);
        } catch (err: any) {
          setError(err);
        }
      } else {
        setError(Error('対象IDが指定されていません'));
      }
    }
    fetchData();
  }, [id, getData]);
  return (
    <FormBlock>
      <MaxWidth maxWidth={586}>
        <View>
          <WithHint id="shopExcludeVisitors">
            <Text>来店集計除外トリマアカウント</Text>
          </WithHint>
          {error ? (
            <Text>{error.message}</Text>
          ) : !visitors ? (
            <ActivityIndicator size={60} />
          ) : (
            <View>
              <VMargin />
              <Text>招待コード</Text>
              <VMargin />
              {visitors.length === 0 ? (
                <Text>{'　'}（設定なし）</Text>
              ) : (
                <FlatList
                  data={visitors.filter((visitor: any) => visitor.id)}
                  renderItem={(visitor) => (
                    <Text>
                      {'　'}
                      {visitor.item.invitedCode}
                    </Text>
                  )}
                />
              )}
            </View>
          )}
        </View>
        <VMargin />
        <MaxWidth maxWidth={586}>
          <TrimaButton
            variant="contained"
            onClick={() => gotoExcludeVisitors()}>
            来店集計除外トリマアカウントを修正
          </TrimaButton>
        </MaxWidth>
      </MaxWidth>
    </FormBlock>
  );
};

const Wrap: React.FC<{style?: ViewStyle; children: React.ReactNode}> = ({
  children,
  style,
}) => {
  return <View style={[styles.container, style]}>{children}</View>;
};

const ShopInfo: React.FC<{shop: SaaSShop}> = ({shop}) => {
  return (
    <FormBlock>
      <MaxWidth maxWidth={586}>
        <Paragraph label="店舗名" value={shop.name} />
        <VMargin />
        <IconImage uri={shop.icon} />
        <VMargin />
        <ShopImages imageUrls={shop.imageUrls} />
        <VMargin />
        <Paragraph label="紹介文" value={shop.text} isLink />
        <VMargin />
        {!shop.legalUrl && (
          <>
            <Paragraph label="郵便番号" value={shop.address?.zip} />
            <VMargin />
            <Paragraph label="都道府県" value={shop.address?.pref} />
            <VMargin />
            <Paragraph label="市区町村" value={shop.address?.city} />
            <VMargin />
            <Paragraph label="町・番地" value={shop.address?.detail} />
            <VMargin />
            <Paragraph label="建物名称" value={shop.address?.building} />
            <VMargin />
            <Place location={shop.location} />
            <VMargin />
          </>
        )}
        <Paragraph label="電話番号" value={shop.phone} />
        <VMargin />
        <Paragraph label="営業時間" value={shop.biztimes} />
        <VMargin />
        <Paragraph label="休業日" value={shop.holidays} />
        <VMargin />
        {shop.legalUrl && (
          <>
            <Paragraph
              label="特定商取引法に基づく表記のURL"
              value={shop.legalUrl}
              isLink
            />
            <VMargin />
          </>
        )}
        <Paragraph label="Webサイト" value={shop.url} isLink />
        <VMargin />
        <Paragraph
          label="カテゴリ"
          value={shop.category && shopCategoryLabels[shop.category]}
        />
        <View style={styles.bigMargin} />
      </MaxWidth>
    </FormBlock>
  );
};

const EditButton: React.FC<{id: string; error: Error | undefined}> = React.memo(
  ({id, error}) => {
    const navigation = useNavigation<NProp>();
    const gotoEdit = () => navigation.navigate('ShopsEdit', {id});
    // エラー時
    if (error) {
      return (
        <View>
          <Text>エラーが発生しました</Text>
          <Text>{error.message}</Text>
        </View>
      );
    }
    return (
      <MaxWidth maxWidth={586}>
        <TrimaButton variant="contained" onClick={gotoEdit}>
          店舗情報を修正
        </TrimaButton>
      </MaxWidth>
    );
  },
);

export const Detail: React.FC = React.memo(() => {
  const [shop, setShop] = React.useState<
    NonNullable<GetSaaSShopQuery['getSaaSShop']> | undefined
  >();
  const [error, setError] = React.useState<Error | undefined>();
  const [showDeleteDialog, setDeleteDialog] = React.useState<boolean>(false);
  const {isOverDesktop} = useResponsive();

  const {selected} = ShopContainer.useContainer();
  const navigation = useNavigation<NProp>();
  const route = useRoute<RProp>();
  const id = route.params?.id;
  // 選択中店舗では削除ボタンを表示させない
  const showDelete = !!(selected?.id && id !== selected.id);

  React.useEffect(() => {
    if (id) {
      graphQLService.getShop(id).then(setShop).catch(setError);
    } else {
      setError(Error('対象IDが指定されていません'));
    }
  }, [id, route]);

  const goBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate('ShopsMain');
    }
  };

  // 取得前
  if (!shop) {
    return <ActivityIndicator size={40} />;
  }
  return (
    <ExcludeVisitorsContainer.Provider>
      <Container>
        <MaxWidth maxWidth={1008 + 16} style={CommonStyles.margin.all}>
          <Box mb={2} sx={{width: 'inherit'}}>
            <BackLink label={Message.BackToList} onClick={goBack} />
          </Box>
          <Menu>店舗情報</Menu>
          <Media mobile>
            <View>
              <View style={CommonStyles.margin.top} />
              <ShopInfo shop={shop} />
            </View>
            <VMargin />
            <View>
              <ShopPreviewDisplay shop={shop} />
            </View>
            <VMargin />
            <EditButton id={shop.id} error={error} />
            <VMargin />
            <View>
              <VisitorsComponent id={id} />
            </View>
            <View style={CommonStyles.margin.bottom} />
          </Media>
          <Media tablet desktop>
            <View style={styles.desktop}>
              <View style={isOverDesktop ? styles.max : styles.fullWidth}>
                <View style={[CommonStyles.flex.row]}>
                  <Wrap style={styles.leftPanel}>
                    <ShopPreviewDisplay shop={shop} />
                  </Wrap>
                  <View style={styles.rightPanel}>
                    <Wrap>
                      <ShopInfo shop={shop} />
                    </Wrap>
                  </View>
                </View>
                <VMargin />
                <EditButton id={shop.id} error={error} />
                <View style={CommonStyles.margin.all}>
                  <View style={CommonStyles.margin.top} />
                  <VisitorsComponent id={id} />
                </View>
              </View>
            </View>
          </Media>
          <View>
            {showDelete && (
              <MaxWidth maxWidth={586}>
                <View style={styles.bigMargin} />
                <TrimaButton
                  variant="contained"
                  onClick={() => setDeleteDialog(true)}
                  color="error">
                  店舗情報を削除する
                </TrimaButton>
              </MaxWidth>
            )}
          </View>
          <ShopDeleteDialog
            visible={showDeleteDialog}
            name={shop.name}
            id={id as string}
            onDismiss={(removed) => {
              setDeleteDialog(false);
              // 削除に成功していたら詳細画面から出る必要がある
              if (removed) {
                goBack();
              }
            }}
            // エラーしたら一覧に戻っておく
            onError={goBack}
          />
        </MaxWidth>
      </Container>
    </ExcludeVisitorsContainer.Provider>
  );
});

const styles = StyleSheet.create({
  iconImage: {
    width: 128,
    height: 128,
    borderWidth: 1,
    borderColor: Colors.darkgray,
  },
  shopImage: {
    width: 60,
    height: 60,
    borderWidth: 1,
    borderColor: Colors.darkgray,
  },
  map: {
    height: 335,
    borderWidth: 1,
    borderColor: Colors.darkgray,
  },
  bigMargin: {
    marginTop: 50,
  },
  container: {
    ...CommonStyles.formBlock,
    ...CommonStyles.margin.all,
    marginBottom: 0,
  },
  desktop: {
    alignItems: 'center',
  },
  fullWidth: {
    alignSelf: 'stretch',
  },
  max: {
    width: BRAKE_POINT.desktop,
  },
  leftPanel: {
    marginRight: 0,
  },
  rightPanel: {
    flex: 1,
  },
});
