import GoogleMapReact from 'google-map-react';
import React, {useEffect, useRef} from 'react';
import {GoogleApiRepository} from '../../services/GoogleApiRepository';
import {LngLat} from '../../services/ZipAddressRepository';
import CenterMark from './CenterMark';
import {MapContainer} from './MapContainer';

const TAG = 'CenterMarkerMap';
console.log(TAG);

type MapRefs = {
  map: any;
  maps: any;
};

type CenterMarkerMapProp = {
  center: LngLat | null;
  zoom: number;
  freezed?: boolean;
};

const MapOptions = {
  minZoom: 15,
  rotateControl: false,
  fullscreenControl: false,
};

const CenterMarkerMap: React.FC<CenterMarkerMapProp> = (
  props: CenterMarkerMapProp,
) => {
  const mapState = MapContainer.useContainer();
  const {center, setCenter} = mapState;
  const mapRefs = useRef<MapRefs>({map: null, maps: null});

  useEffect(() => {
    const prepare = () => {
      if (props.center) {
        setCenter(props.center);
      }
    };
    prepare();
  }, [props.center, setCenter]);

  const handleApiLoaded = (map: any, maps: any) => {
    mapRefs.current = {map, maps};
  };

  const handleBoundsChange = (event: any) => {
    setCenter(event.center);
  };

  const options = props.freezed
    ? {
        disableDefaultUI: true,
        gestureHandling: 'none',
        keyboardShortcuts: false,
      }
    : {...MapOptions};

  const workCenter = center ? center : undefined;
  return (
    <>
      <GoogleMapReact
        bootstrapURLKeys={{key: GoogleApiRepository.KEY}}
        options={options}
        center={workCenter}
        onChange={handleBoundsChange}
        defaultZoom={props.zoom}
        zoom={props.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({map, maps}) => handleApiLoaded(map, maps)}
      />
      <CenterMark />
    </>
  );
};

export default CenterMarkerMap;
