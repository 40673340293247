import {Box} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {
  ActivityIndicator,
  DimensionValue,
  StyleSheet,
  View,
} from 'react-native';
import {Text, TrimaButton} from '../../../components/Elements';
import {CommonStyles} from '../../../theme';
import {SaaSRepository} from '../../services/SaaSRepository';
import {LngLat, ZipAddress} from '../../services/ZipAddressRepository';
import CenterMarkerMap from '../map/CenterMarkerMap';
import {MapContainer} from './MapContainer';

const TAG = 'AddressPickerMap';

export type AddressPickerMapProp = {
  address?: ZipAddress | null;
  detail?: string;
  building?: string;
  center?: LngLat;
  onSubmit: any;
};

const AddressPickerMap: React.FC<AddressPickerMapProp> = (
  props: AddressPickerMapProp,
) => {
  console.log(styles, props);

  const mapState = MapContainer.useContainer();
  const {center, setCenter} = mapState;
  const [loading, setLoading] = useState(false);

  const {onSubmit} = props;

  useEffect(() => {
    const prepare = async () => {
      setLoading(true);
      const {address, detail, building} = props;
      try {
        console.log(TAG, 'use-eff', props);
        if (props.center) {
          setCenter(props.center);
        } else if (address) {
          console.log('picker-effect', address, detail, building);
          const pre = `${address.pref}${address.city}`;
          let text;
          if (detail || building) {
            text = `${pre}${detail || ''}${building || ''}`;
          } else {
            text = `${pre}${address.street || ''}${address.extended || ''}`;
          }

          const result = await SaaSRepository.fetchGooglePlace(text);
          console.log(TAG, 'prepare', result);
          setCenter(result[0].location);
        }
      } finally {
        setLoading(false);
      }
    };
    prepare();
  }, [props, setCenter]);

  const handleSubmit = () => {
    onSubmit(center);
  };
  return (
    <View>
      {loading ? (
        <ActivityIndicator />
      ) : (
        <View>
          <Text style={styles.title}>ピンの位置を修正</Text>
          <View style={styles.map}>
            <CenterMarkerMap center={center} zoom={17} />
          </View>
          <Box p={2}>
            <TrimaButton variant="contained" onClick={handleSubmit}>
              確定
            </TrimaButton>
          </Box>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  title: {
    ...CommonStyles.padding.all,
  },
  map: {
    height: 'calc(100vh - 146px)' as DimensionValue,
  },
});

export default AddressPickerMap;
