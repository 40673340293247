import {Box} from '@mui/material';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {useNavigation, useRoute} from '@react-navigation/native';
import React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';
import {
  BRAKE_POINT,
  Container,
  Media,
  ShopCreateComponent,
  useResponsive,
} from '../../components';
import {
  BackLink,
  FormBlock,
  MaxWidth,
  Menu,
  VForm,
  VMargin,
} from '../../components/Elements';
import {PlacePickerContainer} from '../../components/PlacePicker';
import {
  RealShopForm,
  SubmitForAdd,
} from '../../components/Register/ShopRegister';
import {ShopRegisterContainer} from '../../components/Register/container';
import {
  ShopFormData,
  schemaShopForm,
} from '../../components/Register/shopSchema';
import {ShopPreview} from '../../components/Shop/ShopPreview';
import {OwnerContainer, ShopContainer, ShopsContainer} from '../../container';
import {MainNavigationProp, MainRouteProp} from '../../navigation/MainScreen';
import {CommonStyles, Message} from '../../theme';

type NProp = MainNavigationProp<'ShopsCreate'>;
type RProp = MainRouteProp<'ShopsCreate'>;

const Wrap: React.FC<{style?: ViewStyle; children: React.ReactNode}> = ({
  children,
  style,
}) => {
  return <View style={[styles.container, style]}>{children}</View>;
};

export const Create: React.FC = () => {
  const navigation = useNavigation<NProp>();
  const route = useRoute<RProp>();
  const groupId = route.params?.groupId;
  const {updateShops} = ShopContainer.useContainer();
  const {fetchShops} = ShopsContainer.useContainer();
  const {owner} = OwnerContainer.useContainer();
  const goMain = () => navigation.navigate('ShopsMain');
  const onUpdate = async (shop: any) => {
    await updateShops(shop.id);
    await fetchShops(owner?.id as string);
    await AsyncStorage.setItem('shop', shop.id);
  };
  const {isOverDesktop} = useResponsive();
  return (
    <Container style={CommonStyles.padding.all}>
      <MaxWidth maxWidth={BRAKE_POINT.desktop + 16}>
        <Box mb={2} sx={{width: 'inherit'}}>
          <BackLink label={Message.BackToList} onClick={goMain} />
        </Box>
        <VForm.Provider<ShopFormData>
          schema={schemaShopForm}
          defaultValues={undefined}>
          <ShopRegisterContainer.Provider
            initialState={{images: [], icon: undefined}}>
            <PlacePickerContainer.Provider>
              <Media mobile>
                <Menu>店舗を追加</Menu>
                <View style={CommonStyles.margin.top} />
                <RealShopForm />
                <Wrap>
                  <FormBlock>
                    <ShopCreateComponent />
                  </FormBlock>
                </Wrap>
                <Wrap>
                  <ShopPreview />
                </Wrap>
                <VMargin />
              </Media>
              <Media tablet desktop>
                <View style={styles.desktop}>
                  <View style={isOverDesktop ? styles.max : styles.fullWidth}>
                    <Menu>店舗情報</Menu>
                    <View style={[CommonStyles.flex.row]}>
                      <Wrap style={styles.leftPanel}>
                        <ShopPreview />
                      </Wrap>
                      <View style={styles.rightPanel}>
                        <RealShopForm />
                        <Wrap>
                          <FormBlock>
                            <ShopCreateComponent />
                          </FormBlock>
                        </Wrap>
                      </View>
                    </View>
                    <VMargin />
                  </View>
                </View>
              </Media>
              <MaxWidth maxWidth={586}>
                <SubmitForAdd
                  onSubmit={onUpdate}
                  mode="new"
                  groupId={groupId}
                />
              </MaxWidth>
            </PlacePickerContainer.Provider>
          </ShopRegisterContainer.Provider>
        </VForm.Provider>
        <VMargin />
      </MaxWidth>
    </Container>
  );
};

const styles = StyleSheet.create({
  container: {
    ...CommonStyles.formBlock,
    ...CommonStyles.margin.all,
    marginBottom: 0,
  },
  desktop: {
    alignItems: 'center',
  },
  fullWidth: {
    alignSelf: 'stretch',
  },
  max: {
    width: BRAKE_POINT.desktop,
  },
  leftPanel: {
    marginRight: 0,
  },
  rightPanel: {
    flex: 1,
  },
});
